import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import {
  formatAirFlow,
  formatAlarm,
  formatAltitude,
  formatAngle,
  formatBoolean,
  formatCoordinate,
  formatCourse,
  formatDistance,
  formatDoorStatus,
  formatNumber,
  formatNumericHours,
  formatPercentage,
  formatPressure,
  formatRpm,
  formatSpeed,
  formatTemperature,
  formatTime,
  formatVolume,
} from '../util/formatter';
import { useAttributePreference, usePreference } from '../util/preferences';
import { useTranslation } from './LocalizationProvider';
import { useAdministrator } from '../util/permissions';
import AddressValue from './AddressValue';

const PositionValue = ({ position, property, attribute }) => {
  const t = useTranslation();

  const admin = useAdministrator();

  const device = useSelector((state) => state.devices.items[position.deviceId]);

  const key = property || attribute;
  const value = property ? position[property] : position.attributes[attribute];

  const distanceUnit = useAttributePreference('distanceUnit');
  const altitudeUnit = useAttributePreference('altitudeUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const coordinateFormat = usePreference('coordinateFormat');
  const volumeUnit = useAttributePreference('volumeUnit');

  const parseEvents = (event) => {
    const value = event.toString();
    const attribute = position.attributes;
    switch (position.event !== null) {
      case (value === '0' || value === ''):
        return 'No events to show';
      case (value === '15'):
        return `Driving Score ${attribute.io15}`;
      case (value === '66'):
        return `DISCHARGED Vehicle Battery ${formatNumber(attribute.power, 2)} ${t('sharedVoltAbbreviation')} `;
      case (value === '175'):
        return `BREACH Auto Geofence on ${attribute.io175 === 1 ? 'entry' : 'exit'}`;
      case (value === '200'):
        switch (attribute.io200) {
          case 1:
            return 'GNSS Sleep';
          case 2:
            return 'Deep sleep';
          case 3:
            return 'Online deep dleep';
          case 4:
            return 'Ultra deep sleep';
          default:
            return 'AWAKE';
        }
      case (value === '236'):
        return 'ALARM Triggered';
      case (value === '239'):
        switch (attribute.io239) {
          case 1:
            return 'Ignition ON';
          default:
            return 'Ignition OFF';
        }
      case (value === '240'):
        return 'Motion Detected';
      case (value === '243'):
        return `HARSH Driving - ${formatNumber((attribute.io243 * 0.001), 2)} sec`;
      case (value === '246'):
        return 'TOWING';
      case (value === '247'):
        switch (attribute.io247) {
          case 2:
            return 'ACCIDENT - limited crash trace (device not calibrated)';
          case 3:
            return 'ACCIDENT - limited crash trace (device is calibrated)';
          case 4:
            return 'ACCIDENT - full crash trace (device not calibrated)';
          case 5:
            return 'ACCIDENT - full crash trace (device is calibrated)';
          default:
            return 'ACCIDENT Detected';
        }
      case (value === '249'):
        return 'JAMMING';
      case (value === '250'):
        switch (attribute.io250) {
          case 0:
            return 'Trip STOP';
          case 1:
            return 'Trip START';
          case 2:
            return 'Trip BUSINESS';
          case 3:
            return 'Trip PRIVATE';
          default:
            return 'Trip CUSTOM Status';
        }
      case (attribute.sat < 4):
        return `LOST GPS Fix - Position Inaccurate ${attribute.sat} Satelites`;
      case (value === '251'):
        switch (attribute.io251) {
          case 1:
            return 'IDLING';
          default:
            return 'Driving';
        }
      case (value === '252'):
        switch (attribute.io252) {
          case 0:
            return 'Device Plugged';
          default:
            return 'Device REMOVED';
        }
      case (value === '253'):
        switch (attribute.io253) {
          case 1:
            return `Hard ACCELERATION ${formatNumber((attribute.io254 * 0.01), 2)} G`;
          case 2:
            return `Hard BRAKING ${formatNumber((attribute.io254 * 0.01), 2)} G`;
          default:
            return `Hard CORNERING ${formatNumber((attribute.io254 * 0.01), 2)} G`;
        }
      default:
        return `Unassigned value ${value}`;
    }
  };

  const formatValue = () => {
    switch (key) {
      case 'fixTime':
      case 'deviceTime':
      case 'serverTime':
        return formatTime(value);
      case 'latitude':
        return formatCoordinate('latitude', value, coordinateFormat);
      case 'longitude':
        return formatCoordinate('longitude', value, coordinateFormat);
      case 'io38':
      case 'io59':
        return formatAngle(value);
      case 'speed':
      case 'io37':
      case 'io81':
        return formatSpeed(value, speedUnit, t);
      case 'course':
        return formatCourse(value);
      case 'altitude':
        return formatAltitude(value, altitudeUnit, t);
      case 'io36':
      case 'io85':
        return formatRpm(value);
      case 'door':
        return formatDoorStatus(value);
      case 'io34':
      case 'io35':
      case 'io44':
      case 'io45':
      case 'io50':
      case 'io56':
        return formatPressure(value);
      case 'io40':
        return formatAirFlow(value);
      case 'power':
      case 'battery':
      case 'io51':
      case 'io543':
        return `${formatNumber(value)} ${t('sharedVoltAbbreviation')}`;
      case 'io32':
      case 'coolantTemp':
      case 'io39':
      case 'io53':
      case 'io58':
        return formatTemperature(value);
      case 'io68':
      case 'io544':
        return `${formatNumber((value * 0.001), 3)} mA`;
      case 'batteryLevel':
      case 'io31':
      case 'io33':
      case 'io41':
      case 'io46':
      case 'io47':
      case 'io48':
      case 'io52':
      case 'io57':
      case 'io82':
      case 'io89':
      case 'io111':
      case 'io113':
      case 'io114':
        return formatPercentage(value, t);
      case 'io12':
        return formatVolume(value, volumeUnit, t);
      case 'io83':
      case 'io84':
      case 'io107':
      case 'io112':
      case 'io390':
        return formatVolume(value * 0.1, volumeUnit, t);
      case 'io60':
      case 'io110':
        return `${formatVolume(value * 0.1, volumeUnit, t)}/h`;
      case 'io175':
        return `BREACH Auto Geofence on ${value === 1 ? 'entry' : 'exit'}`;
      case 'io200':
      case 'sleepMode':
        switch (value) {
          case 1:
            return 'GNSS Sleep';
          case 2:
            return 'Deep sleep';
          case 3:
            return 'Online deep dleep';
          case 4:
            return 'Ultra deep sleep';
          default:
            return 'AWAKE';
        }
      case 'io251':
        return formatBoolean(value, t);
      case 'io246':
        return value === 1 ? 'TOWING' : 'No';
      case 'io250':
        return value === 1 ? 'STARTED' : 'STOPPED';
      case 'io263':
        switch (value) {
          case 1:
            return 'Enabled no connected devices';
          case 2:
            return 'Device connected BTv3 only';
          case 3:
            return 'Device connected BLE only';
          case 4:
            return 'Device connected BLE + BT';
          default:
            return 'BT Disabled';
        }
      case 'io283':
        switch (value) {
          case 1:
            return 'Ignition ON';
          case 2:
            return 'Driving';
          default:
            return 'Ignition OFF';
        }
      case 'io497':
      case 'io498':
        switch (value) {
          case 1:
            return 'No SD Card';
          case 2:
            return 'SD Card mount FAILED';
          case 3:
            return 'SD Card Mounted';
          case 4:
            return 'BAD SD Card';
          default:
            return 'Not Detected';
        }
      case 'alarm':
        return formatAlarm(value, t);
      case 'odometer':
      case 'distance':
      case 'totalDistance':
      case 'io43':
      case 'io49':
      case 'io87':
      case 'io105':
      case 'io389':
        return formatDistance(value, distanceUnit, t);
      case 'hours':
      case 'io42':
      case 'io55':
      case 'io102':
      case 'io103':
        return formatNumericHours(value, t);
      case 'axisX':
      case 'axisY':
      case 'axisZ':
        return `${value} mG`;
      case 'gpsStatus':
      case 'io69':
        switch (value) {
          case 1:
            return 'ON with fix';
          case 2:
            return 'ON without fix';
          case 3:
            return 'SLEEP';
          default:
            return 'OFF';
        }
      case 'io80':
      case 'workMode':
      case 'dataMode':
        switch (value) {
          case 1:
            return 'Home Network: when moving';
          case 2:
            return 'Roaming: when stopped';
          case 3:
            return 'Roaming: when moving';
          case 4:
            return 'Unknown Network: when stopped';
          case 5:
            return 'Unknown Network: when moving';
          default:
            return 'STOPPED:Home Network: when';
        }
      case 'event':
        return parseEvents(value);
      default:
        if (typeof value === 'number') {
          return formatNumber(value);
        } if (typeof value === 'boolean') {
          return formatBoolean(value, t);
        }
        return value || '';
    }
  };

  switch (key) {
    case 'image':
    case 'video':
    case 'audio':
      return (<Link href={`/api/media/${device.uniqueId}/${value}`} target="_blank">{value}</Link>);
    case 'totalDistance':
    case 'hours':
      return (
        <>
          {formatValue(value)}
          &nbsp;&nbsp;
          {admin && (<Link component={RouterLink} underline="none" to={`/settings/accumulators/${position.deviceId}`}>&#9881;</Link>)}
        </>
      );
    case 'address':
      return (<AddressValue latitude={position.latitude} longitude={position.longitude} originalAddress={value} />);
    case 'network':
      if (value) {
        return (<Link component={RouterLink} underline="none" to={`/network/${position.id}`}>{t('sharedInfoTitle')}</Link>);
      }
      return '';
    default:
      return formatValue(value);
  }
};

export default PositionValue;
